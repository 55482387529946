import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.APP_ENV !== 'dev') {
    Sentry.init({
        dsn: process.env.SENTRY_URL,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.APP_ENV,
    });
}